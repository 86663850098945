import React, { useEffect, useState } from "react";
import {FormattedMessage} from "react-intl";
import {Link, useHistory} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/bootstrap/text-field";
import Form from "formsy-react";
import Panel from "./panel";
import { formatLocation, searchServiceAvailabilityQuery } from "../../components/logic/address";
import {setFormData} from "../../components/manager/form";
import LandingBgImage from "../../../static/content/join-us/zolt-landing-bg.png";

import root from "window-or-global";
import { useQuery } from "@apollo/client";


export default function Coupon(props) {
  const [locationId, setLocationId] = useState(null);
  const [voucherCode, setVoucherCode] = useState(null);
  const history = useHistory();


  function handleOnSubmit({voucherCode}) {
    setFormData({voucherCode});
    return history.push("/plan");
  }

  useEffect(() => {
    const params = new URLSearchParams(root.location.search);
    const locationParam = params.get("locationId") || "";
  
    const voucherCode = params.get("code");
    if (!locationParam || locationParam === "" || locationParam === "undefined") {
      root.location = "/join-us";
    }
    setFormData({ locationId: locationParam });
    setLocationId(locationParam);
    setVoucherCode(voucherCode);
  }, []);
  const results = useQuery(searchServiceAvailabilityQuery, {
    variables: {
      address: locationId,
    },
    skip: !(locationId),
  });
  if (results.loading) {
    return (<React.Fragment/>);
  }

  if (!results.loading && !(locationId)) {
    return <React.Fragment />;
  }
  const address = results.data?.classMethods?.Address?.searchAddressServiceability[0];;
  const errorCheck = (results?.error?.graphQLErrors || []);
  let errorCheckCode;
  if (errorCheck.length > 0) {
    errorCheckCode = errorCheck[0].message;
  } else if (!address) {
    errorCheckCode = "EUKNOWN";
  }


  if (errorCheckCode) {
    let errorMessage;
    switch (errorCheckCode) {
      case "EACTIVE":
        errorMessage = "There is already an active service at the provided location.";
        break;
      case "EUNKNOWN":
        errorMessage = "Unable to find a service at the provided address.";
        break;
      case "EBUILDING":
        errorMessage = "Unable to find your building. Please contact support or try again later.";
        break;
      default:
        errorMessage = "An unknown error has occurred please contact support or try again later.";
        break;
    }

    return (<div style={{width: "100%", minHeight: "100vh", backgroundImage: `url(${LandingBgImage})`, backgroundSize: "cover"}}>
      <div style={{width: "100%", minHeight: "100vh"}}>
        <Panel className="coupon coupon-error" style={{background: "none"}}>
          <Container>
            <Row>
              <Col>
                <div className="coupon-title">
                  {"Unable to continue!"}
                </div>
              </Col>
              <Col xs={12}>
                <div className="coupon-sub-title">
                  {errorMessage}
                </div>
              </Col>
              <Col xs={12}>
                <div className="coupon-sub-title" style={{marginTop: 30, marginBottom: 20, fontSize: 24}}>
                  {"Please contact us for further assistance."}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="auto" className="mx-auto">
                <a href="/join-us" className="vw-button orange" style={{padding: "9px 20px", height: 39}}>
                  {"Go Back"}
                </a>
              </Col>
            </Row>
          </Container>
        </Panel>
      </div>
    </div>);
  }
  setFormData({address});


  return (<div style={{width: "100%", minHeight: "100vh", backgroundImage: `url(${LandingBgImage})`, backgroundSize: "cover"}}>
    <div style={{width: "100%", minHeight: "100vh"}}>
      <Panel noPaper className="coupon" style={{background: "none"}}>
        <Container>
          <Row>
            <Col>
              <div className="coupon-title">
                {"Great News!"}
              </div>
            </Col>
            <Col xs={12}>
              <div className="coupon-sub-title">
                {"Our network is available at "}
                <div className="coupon-building-title">
                  {formatLocation(address)}
                </div>
              </div>
            </Col>
          </Row>
          <Form className="section" onValidSubmit={handleOnSubmit}>
            <Row className="align-items-center no-gutters" style={{paddingTop: 50}}>
              <Col className="ml-auto" sm={4} xs={12}>
                <div style={{marginBottom: 4}}>
                  <TextField
                    name="voucherCode"
                    className="vw-form-field highlight input-radius-1 coupon-field"
                    required
                    autoComplete="off"
                    placeholder={"Have a voucher code? enter it here!"}
                    validations={{
                      maxLength: 20,
                      isAlphanumeric: true,
                      isExisty: true,
                    }}
                    validationErrors={{
                      isExisty: (<FormattedMessage id="validation.errors.required.coupon" />),
                      maxLength: (<FormattedMessage id="validation.errors.maxLength50" />),
                      isAlphanumerical: (<FormattedMessage id="validation.errors.isAlphanumeric" />),
                    }}
                    defaultValue={voucherCode}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      fontSize: "16 !important",
                    }}
                  />
                </div>
              </Col>
              <Col xs="auto" style={{marginRight: 30}}>
                <input type="submit" className="vw-button coupon-apply evergy-blue brder hideicon input-radius-2" value="Apply" />
              </Col>
              <Col xs={"auto"} className="mr-auto">
                <Link to="/plan" className="vw-button dark-blue coupon-next h-100">
                  {"Next"}
                </Link>
              </Col>
            </Row>
          </Form>
        </Container>
      </Panel>
    </div>
  </div>);
}
