import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TextField from "../../../components/bootstrap/text-field";
import Form from "formsy-react";
import { getFormData, setFormData } from "../../../components/manager/form";
import SelectField from "../../../components/bootstrap/select-field";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import {
  getCountriesQuery,
  getCountriesResult,
} from "../../../components/logic/address";

export default function UserDetailsAddress(props) {
  const { address, hardware, unitNo, countryId } = getFormData();
  // const txtStreetNo = useRef();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const countryQuery = useQuery(getCountriesQuery);
  if (countryQuery.loading) {
    return <React.Fragment />;
  }

  const countries = getCountriesResult(countryQuery);
  const defaultCountry = countryId
    ? countryId
    : (countries || []).reduce((prev, value) => {
      return value && value.name === "Australia" ? value.id : prev;
    }, {});

  function findCountryById(countryId) {
    return (countries || []).reduce((prev, value) => {
      return value && value.id === countryId ? value : prev;
    }, {});
  }

  function handleOnSubmit(data) {
    const country = countries.find(c=>c.id === data.countryId)?.name;
    setFormData({
      countryData: findCountryById(data.countryId),
      homeAddress: {
        ...data,
        country,
      },
    });
    if (hardware) {
      return history.push("/user/shipping-address");
    } else {
      return history.push("/user/phone");
    }
  }
  return (
    <Form className="section" onValidSubmit={handleOnSubmit}>
      <div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <FormattedMessage id="register.address.title" />
              </div>
            </Col>
          </Row>
          {unitNo &&
            <Row className="align-items-center inline">
              <label className="col-lg-2">{"Unit No."}</label>
              <Col lg={4}>
                <TextField
                  name="unitNo"
                  className="vw-form-field input-radius"
                  required
                  // placeholder={formatMessage({id: "placeholders.streetNo"})}
                  validations={{
                    maxLength: 50,
                    isExisty: true,
                  }}
                  validationErrors={{
                    maxLength: (
                      <FormattedMessage id="validation.errors.maxLength50" />
                    ),
                  }}
                  value={unitNo || ""}
                  readOnly
                />
              </Col>
            </Row>
          }
          <Row className="align-items-center inline">
            <label className="col-lg-2">{"Street No"}</label>
            <Col lg={2}>
              <TextField
                name="streetNo"
                className="vw-form-field input-radius"
                value={address.streetNo}
                required
                validations={{
                  maxLength: 50,
                  isExisty: true,
                }}
                validationErrors={{
                  maxLength: (
                    <FormattedMessage id="validation.errors.maxLength50" />
                  ),
                }}
                readOnly
              />
            </Col>
            <label className="col-lg-2">{"Address"}</label>
            <Col lg={6}>
              <TextField
                name="address"
                className="vw-form-field input-radius"
                // placeholder={formatMessage({id: "placeholders.streetAddress"})}
                value={address.address}
                required
                validations={{
                  maxLength: 50,
                  isExisty: true,
                }}
                validationErrors={{
                  maxLength: (
                    <FormattedMessage id="validation.errors.maxLength50" />
                  ),
                }}
                readOnly
              />
            </Col>
          </Row>
          <Row className="align-items-center inline">
            <label className="col-lg-2">{"City"}</label>
            <Col lg={4}>
              <TextField
                name="suburb"
                className="vw-form-field input-radius"
                // placeholder={formatMessage({id: "placeholders.streetAddress"})}
                value={address.suburb}
                required
                validations={{
                  maxLength: 50,
                  isExisty: true,
                }}
                validationErrors={{
                  maxLength: (
                    <FormattedMessage id="validation.errors.maxLength50" />
                  ),
                }}
                readOnly
              />
            </Col>
            <label className="col-lg-2">{"State"}</label>
            <Col lg={4}>
              <TextField
                name="state"
                className="vw-form-field input-radius"
                value={address.state}
                required
                validations={{
                  maxLength: 50,
                }}
                validationErrors={{
                  maxLength: (
                    <FormattedMessage id="validation.errors.maxLength50" />
                  ),
                }}
                readOnly
              />
            </Col>
          </Row>
          <Row className="align-items-center inline">
            <label className="col-lg-2">{"Country"}</label>
            <Col lg={4}>
              <SelectField
                readOnly
                disabled
                required
                defaultValue={address.countryId || defaultCountry}
                name="countryId"
                className="vw-form-field"
                inputStyle={{ borderRadius: 10, paddingLeft: 10 }}
              >
                {(countries || []).map((country) => {
                  return (
                    <option value={(country || {}).id} key={(country || {}).id}>
                      {(country || {}).name}
                    </option>
                  );
                })}
              </SelectField>
            </Col>
            <label className="col-lg-2">{"Postal Code"}</label>
            <Col lg={4}>
              <TextField
                name="postCode"
                className="vw-form-field input-radius"
                value={address.postCode}
                // required
                validations={{
                  maxLength: 50,
                }}
                validationErrors={{
                  maxLength: (
                    <FormattedMessage id="validation.errors.maxLength50" />
                  ),
                }}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="input-row">
                <div className="submit">
                  <span>
                    <FormattedMessage id="register.userDetails.keyPressEnter" />
                  </span>
                  <input
                    className="vw-button orange next-btn"
                    type="submit"
                    value={formatMessage({
                      id: "register.userDetails.buttonConfirm",
                    })}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Form>
  );
}
